<template>
    <div class="news-container">        
        <b-carousel deck class="main-list mb-5 news"
            id="carousel-1"
            :interval="slideInterval"
            controls
            indicators
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
        >
            <b-carousel-slide 
                v-for="(textItem, index) in textList" :key="index"
                :caption="textItem.header" 
                :img-src="'../images/'+textItem.img"
            >
                <div class="slide-info">
                    <p class="info">{{ textItem.text }}</p>
                    <h2 v-if="textItem.linkText">Szczegóły w sekcji <b><a href="#" v-scroll-to="{el: '#'+ textItem.link, offset: -60 }">{{ textItem.linkText }}</a></b> !</h2>
                </div>
            </b-carousel-slide>
        </b-carousel>
        <h2 class="subtitle end">Już wkrótce kolejne nowości! :)</h2>
    </div>
</template>

<script>
import aboutText from "../../public/json/news.json"
export default {
    name: "NewsSection",
    data() {
        return {
            textList: aboutText,
            slideInterval: window.innerWidth < 720 ? 0 : 0
        }
    }
}
</script>