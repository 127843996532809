import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from "bootstrap-vue"
import VueAgile from 'vue-agile'
import VueScrollTo from 'vue-scrollto';
import VueProgressiveImage from 'vue-progressive-image';
import VueLazyLoad from 'vue-lazyload'
import VueViewer from 'v-viewer'
import Lightbox from '@morioh/v-lightbox'
import AOS from 'aos';

import 'leaflet/dist/leaflet.css';
import 'viewerjs/dist/viewer.css'
import '@morioh/v-lightbox/dist/lightbox.css';
import 'aos/dist/aos.css';

Vue.use(VueAgile);
Vue.use(VueScrollTo);
Vue.use(BootstrapVue);
Vue.use(VueProgressiveImage);
Vue.use(VueLazyLoad);
Vue.use(VueViewer);
Vue.use(Lightbox);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
